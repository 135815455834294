<template>
  <div class="outer-page">
    <x-table :no-data-text="'暂无数据'" :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @search="search" @add="add" @loadEnter="loadEnter" @loadExpend="loadExpend" @page-change="pageChange" @page-size-change="pageSizeChange"></x-table>
    <Modal v-model="modal.show" :title="modal.title" :width="900" @on-visible-change="modalChange">
      <Form :label-width="140" ref="form" :model="form" :rules="rules">
        <Row>
          <Col span="11">
          <FormItem label="区域名称" prop="serviceName">
            <Input v-model="form.serviceName" placeholder="请输入区域名称" clearable></Input>
          </FormItem>
          </Col>
          <Col span="11" offset="1">
          <FormItem label="联系地址" prop="serviceAddress">
            <Input clearable placeholder="请输入联系地址" v-model="form.serviceAddress"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
          <FormItem label="联系人" prop="name">
            <Input clearable placeholder="请输入联系人" v-model="form.name" :disabled="modal.title=='编辑区域'"></Input>
          </FormItem>
          </Col>
          <Col span="11" offset="1">
          <FormItem label="联系电话" prop="tel">
            <Input clearable placeholder="请输入联系电话" v-model="form.tel" :disabled="modal.title=='编辑区域'"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
          <FormItem label="是否站长" prop="isStationmaster">
            <Select clearable placeholder="请选择是否站长" v-model="form.isStationmaster">
              <Option value="0">否</Option>
              <Option value="1">是</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="11" offset="1">
          <FormItem label="管辖区域" prop="region">
            <Cascader :data="areaList" change-on-select v-model="form.region" :load-data="loadData" placeholder="请选择管辖区域"></Cascader>
          </FormItem>
          </Col>
          <!-- <Col span="11" offset="1">
          <FormItem label="位置" prop="thirdLongLat">
            <Input v-model="form.thirdLongLat" disabled placeholder="点击右侧图标选择位置">
            <span slot="append" class="iconfont" @click="mapModalShow" style="cursor: pointer">&#xe648;</span>
            </Input>
          </FormItem>
          </Col> -->
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (this.modal.show = false)">取消</Button>
      </p>
    </Modal>

    <Modal v-model="mapModel" title="选择位置" fullscreen>
      <search-map v-if="mapModel" :currentData="currentData" @back-location="dealMapData"></search-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (mapModel = false)">提交</Button>
        <Button @click="() => (mapModel = false)">取消</Button>
      </p>
    </Modal>

    <cooperation-detail v-model="cooperationDetail.show" :cooperationDetailInfo="cooperationDetail.info"></cooperation-detail>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import searchMap from '@/components/searchMap'
import CU from '@/common/util'
import cooperationDetail from './cooperationDetail.vue'
export default {
  name: '',
  components: { searchMap, cooperationDetail },
  data() {
    return {
      table: {
        columns: [
          {
            title: '名称',
            key: 'serviceName',
          },
          {
            title: '管辖区域',
            tooltip: true,
            key: 'regionCodeName',
          },
          {
            title: '联系人',
            key: 'name',
          },
          {
            title: '联系电话',
            key: 'tel',
          },
          {
            title: '联系地址',
            key: 'serviceAddress',
          },
          {
            title: '是否站长',
            key: 'isStationmaster',
            render: (h, { row }) => {
              if(row.isStationmaster == 1) return <span>是</span>
              return <span>否</span>
            }
          },
          {
            title: '操作',
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA('regionManagement_edit') && (
                    <a
                      style="margin-right:10px"
                      on-click={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA('regionManagement_delete') && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a style="margin-right: 10px">删除</a>
                    </Poptip>
                  )}
                  {this.CA('car_view') && (
                    <a onClick={() => this.checkInfo(row)}>详情</a>
                  )}
                </div>
              )
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        show: false,
        title: '',
        submitLoading: false,
      },
      form: {
        id: '',
        businessScopeName: '',
        businessScope: [],
        name: '',
        tel: '',
        isStationmaster: '0',
        serviceAddress: '',
        type: 200,
        category: '',
        thirdLongLat: '',
        serviceName: '',
        //社会统一信用代码
        creditCode: '',
        //企业类型
        enterpriseType: '',
        //营业期限
        businessTerm: '',
        //所属行业
        industry: '服务业',
        //管辖区域
        regionCode: '',
        region: [],
        userId:'',
      },
      rules: {
        serviceName: [{ required: true, message: '请填写区域名称' }],
        // businessScope: [{ required: true, message: "请选择业务范围" }],
        name: [{ required: true, message: '请填写联系人' }],
        isStationmaster: [{ required: true, message: '请选择是否站长' }],
        tel: [
          { required: true, message: '请填写联系电话' },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message)
                return
              }
              callback()
            },
            message: '手机号格式不正确',
            trigger: 'change',
          },
        ],
        serviceAddress: [{ required: true, message: '请填写组织地址' }],
        // thirdLongLat: [{ required: true, message: "请选择位置" }],
        // creditCode: [{ required: true, message: "请输入社会统一信用代码" }],
        // enterpriseType: [{ required: true, message: "请选择企业类型" }],
        // businessTerm: [{ required: true, message: "请输入营业期限" }],
        // industry: [{ required: true, message: "请输入所属行业" }],
        region: [{ required: true, message: "请选择管辖区域" }],
      },
      mapModel: false,
      currentData: null,
      companyData: [],
      //省市区列表
      areaList: [],
      cooperationDetail: {
        //详情
        show: false,
        info: {},
      },
      businessScopeData: [],
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: '新增区域',
            ca: 'regionManagement_add',
          },
          width: 200,
          filterBox: [
            {
              conditionName: '组织名称',
              component: 'input',
              field: 'serviceName',
              defaultValue: '',
            },
            {
              conditionName: '联系人',
              component: 'input',
              field: 'name',
              defaultValue: '',
            },
            {
              conditionName: '联系电话',
              component: 'input',
              field: 'tel',
              defaultValue: '',
            },
            {
              conditionName: '区域',
              component: 'cascader',
              field: 'regionCodes',
              defaultValue: [],
              data: this.areaList,
              isLoadData: true,
              loadData: this.loadData,
            },
          ],
        },
        page: { ...this.page },
      }
      return config
    },
  },
  methods: {
    mapModalShow() {
      this.currentData = this.form.thirdLongLat
      this.mapModel = true
    },
    dealMapData(positionData) {
      this.form.thirdLongLat = positionData.thirdLongLat
    },
    getList() {
      this.table.loading = true
      this.$post(this.$api.REGULATORY.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        type: 200,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list
          this.page.total = +res.total
        })
        .catch((e) => {
          this.table.data = []
        })
        .finally(() => {
          this.table.loading = false
        })
    },
    //企业类型
    getCompanyCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '203',
      }).then((res) => {
        this.companyData = res.list
      })
    },
    search(value) {
      // this.search_data = value;
      let n
      for (let i = 0; i < value.regionCodes.length; i++) {
        n = value.regionCodes[value.regionCodes.length - 1]
      }
      let obj = {
        ...value,
        regionCode: n,
      }
      this.search_data = obj
      this.getList()
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: '500100',
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name
          item.value = item.id
          if (item.hasChild == 'true') {
            item.loading = false
          }
          item.children = []
        })
        this.areaList = res
      })
    },
    loadData(item, callback) {
      item.loading = true
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((itm) => {
            itm.label = itm.name
            itm.value = itm.id
            // if (item.hasChild == 'true') {
            //   item.loading = false
            //   item.children = []
            // }
          })
          item.children = res
          callback()
        })
        .finally(() => {
          item.loading = false
        })
    },
    //导入
    loadEnter() {
      let input = document.createElement('input')
      input.type = 'file'
      input.onchange = (e) => {
        if (!input.files || input.files.length === 0) return
        let formdata = new FormData()
        formdata.append('file', input.files[0])
        formdata.append('userId', localStorage.getItem('userId'))
        formdata.append('companyNo', localStorage.getItem('companyNo'))
        formdata.append('category', this.user.category + 1)
        formdata.append('type', 1)
        this.$post(this.$api.REGULATORY.UPLOAD, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(() => {
          this.$Message.success('导入成功')
          this.getList()
        })
      }
      input.click()
    },
    add() {
      this.modal = {
        show: true,
        title: '新增区域',
        submitLoading: false,
      }
    },
    edit(row) {
      for (let key in row) {
        this.form[key] = row[key]
      }
      this.form.region = row.regionCode.split(',')
      console.log(this.form.region);
      this.form.businessScope = row.businessScope.split(',')
      this.modal = {
        show: true,
        title: '编辑区域',
        submitLoading: false,
      }
    },
    delete(id) {
      this.$post(this.$api.REGULATORY.DELETE, {
        id,
      }).then(() => {
        this.$Message.success('删除成功！')
        this.getList()
      })
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return
        let params = this.form
        params.category = this.user.category + 1
        params.regionCode = this.form.region.join(',')
        params.businessScope = this.form.businessScope.join(',')
        // params.businessScopeName = this.form.businessScope.join(",");
        delete params.region
        let url
        params.id
          ? (url = this.$api.REGULATORY.UPDATE)
          : (url = this.$api.REGULATORY.ADD)
        this.$post(url, params).then(() => {
          this.$Message.success(params.id ? '修改成功！' : '添加成功！')
          this.getList()
          this.modal.show = false
          this.modal.submitLoading = false
        })
      })
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },
    modalChange(visible) {
      if (visible) return
      this.form = {
        id: '',
        businessScope: [],
        businessScopeName: '',
        name: '',
        tel: '',
        isStationmaster: '0',
        serviceAddress: '',
        type: 200,
        category: '',
        thirdLongLat: '',
        //社会统一信用代码
        creditCode: '',
        //企业类型
        enterpriseType: '',
        //营业期限
        businessTerm: '',
        //所属行业
        industry: '服务业',
        //管辖区域
        regionCode: '',
        serviceName: '',
        userId:'',
      }
      this.$refs.form.resetFields()
    },
    loadExpend() {
      let columns = this.table.columns.map((item) => item.title)
      columns.pop()
      let data = this.table.data.map((row) => {
        let rowData = this.table.columns.map((column) => {
          return row[column.key]
        })
        return rowData
      })
      CU.exportExcel(columns, data, '社会化服务组织')
    },
    //详情
    checkInfo(row) {
      this.cooperationDetail = {
        show: true,
        info: row,
      }
      // console.log(row);
    },
    //业务范围接口
    getBusinessScopeData() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '255',
      }).then((res) => {
        this.businessScopeData = res.list
        // console.log(res)
      })
    },
  },
  mounted() {
    this.getList()
    this.getCompanyCategory()
    this.getAreaList()
    this.getBusinessScopeData()
  },
}
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>